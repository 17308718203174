<template>
    <v-container>
        <page-filters-section top-img="/img/enhancement/enhancement-header.png">
            <div class="d-flex align-center justify-center flex-column">
                <h1>Wizarropedia</h1>
                <h4>Spells list</h4>
            </div>
        </page-filters-section>

        <div class="page-content">
            <v-card dark class="transparent">
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    class="transparent"
                    :headers="headers"
                    :items="spellsListReady"
                    :loading="0 === spellsListReady.length"
                    loader-height="10"
                    :items-per-page=200
                    :hide-default-footer="true"
                    :sortable="true"
                    sort-by="element"
                    :search="search"
                    mobile-breakpoint="690"
                >
                    <template v-slot:item.element="{ item }">
                        <div class="d-flex align-center justify-start">
                            <img :src="`/img/icons/element/32/${item.element}.png`" class="table-img element-img"
                                 :alt="item.element"/>
                            <span class="text-capitalize">{{ item.element }}</span>
                        </div>
                    </template>
                    <template v-slot:item.name="{ item }">
                        <div class="d-flex align-center justify-start">
                            <img :src="spellImg(item)" class="table-img" :alt="item.name"/>
                            <span>{{ item.name }}</span>
                        </div>
                    </template>
                    <template v-slot:item.type="{ item }">
                        <span class="text-capitalize">{{ item.type }}</span>
                    </template>
                    <template v-slot:item.marketplace="{ item }">
                        <v-btn :to="{ name: 'Marketplace', params: {search: item.name}}">
                            Buy Wizard
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>

        </div>
    </v-container>
</template>

<script>
import {apiServiceVue} from "@/services/apiService.vue";
import PageFiltersSection from "@/components/page-filters-section";

export default {
    components: {PageFiltersSection},
    data() {
        return {
            headers: [
                {text: 'Element', value: 'element'},
                {text: 'Name', value: 'name'},
                {text: 'Type', value: 'type'},
                {text: 'Description', value: 'technical_description'},
                {text: 'Marketplace', value: 'marketplace'},
            ],
            search: '',
            spellsList: [],
        };
    },
    mounted: function () {
        this.getAllEnhancements();
    },
    computed: {
        spellsListReady() {
            return this.spellsList;
        },
    },
    methods: {
        init() {
        },
        spellImg(item) {
            return `/img/spells/120x120/${item.type}/${item.element}/${item.icon}`;
        },
        getAllEnhancements() {
            const url = process.env.VUE_APP_APP_URL + 'wizards/spells';
            apiServiceVue.get(url).then(res => {
                this.spellsList = res.data;
            })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Cannot fetch data'
                    });
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.v-application .page-content {
    margin-top: 130px;
}

::v-deep .top-section {
    height: 160px;
}

.page-content {
    margin-bottom: 50px;
}

.table-img {
    width: 48px;
    height: 48px;
    margin: 5px 15px 5px 0;

    &.element-img {
        width: 32px;
        height: 32px;
        margin-right: 5px;
    }
}
</style>

<style lang="scss">
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
    margin: 10px 5px; // margin between each row
    border: 1px solid #ededed;
    display: block; // display table row as block
}
</style>
